<template>
  <b-row>
    <b-col sm="12">
      <!-- <TableDataWidget
        :ref="`tableUserInfo`"
        :title="`ข้อมูลลูกค้า`"
        :url="`/api/supports/client/fetch-all`"
        :columns="columns"
        selectAble
        viewAble
        @clickSelect="clickSelect"
      /> -->

      <TableDataServerSide
        :ref="`tableUserInfo`"
        :title="`ข้อมูล ลูกค้า`"
        :url="`/api/supports/client/fetch-all`"
        @clickView="clickView"
        :columns="columns"
        viewAble
        :btnText="`เพิ่มข้อมูล ลูกค้า`"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      results: [],
      columns: [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'email',
        },
        {
          label: 'ชื่อ สกุล',
          field: 'fullName',
        },
        {
          label: 'ยอดคงเหลือ',
          field: 'credit_sandbox',
        },
        {
          label: 'สมัครจาก',
          field: 'provider',
        },
        {
          label: '',
          field: 'action',
        },
      ],
    }
  },
  methods: {
    clickSelect(data) {
      // console.log('data', data)
      this.$router.push({ name: 'supports-add-credit', params: { data } })
    },
    clickView(data) {
      this.$router.push(`/supports/customers/${data.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
